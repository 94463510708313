import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

const ViewMoreLess = ({ children }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const contentRef = useRef(null)

  const toggleExpand = () => setIsExpanded(!isExpanded)

  return (
    <div className='ViewMoreLess'>
      <div className={cn({ 'ViewMoreLess-gradient': !isExpanded })} />
      <div
        ref={contentRef}
        className={cn('ViewMoreLess-content', { isExpanded })}
      >
        {children}
      </div>
      <div className='ViewMoreLess-button'>
        <button
          className='Button Button--link Button--kbdOnlyFocus'
          onClick={toggleExpand}
        >
          {isExpanded ? 'View Less -' : 'View More +'}
        </button>
      </div>
    </div>
  )
}

ViewMoreLess.propTypes = {
  children: PropTypes.node.isRequired
}


export default ViewMoreLess
