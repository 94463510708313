import PropTypes from 'prop-types'
import React from 'react'

const PolicyInfo = ({isFxbo}) => {
  const contactBy = isFxbo ? 'homeowner' : 'real estate professionals'

  return (
    <div id='terms-info' className='Text Text--a1 Text--modalBottom Text--lineHeight16px'>
      {/* By selecting [Send], you agree that Zillow Inc. (including Out East) and the {contactBy} you have selected may contact you
      about your search. This contact may involve automated means and prerecorded/artificial voices. You do not need to consent
      as a condition of buying any property, goods, or services. Message/data rates may apply.
      <br /><br /> */}

      {/* By selecting [Send], you also agree to our */}
      By selecting [Send], you agree to our
      {' '}
      <a
        href='/terms'
        target='_blank'
        className='Link Link--inline Link--simple'
      >
        Terms of Use
      </a>
      {' '}
      and
      {' '}
      <a
        href='/privacy-policy'
        target='_blank'
        className='Link Link--inline Link--simple'
      >
        Privacy Policy
      </a>
      .
    </div>
  )
}

PolicyInfo.propTypes = {
  isFxbo: PropTypes.bool.isRequired
}


export default PolicyInfo
