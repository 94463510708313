import React from 'react'
import { observer } from 'mobx-react'
import Modal from '../../../Shared/Modal'
import PricePeriodList from './PricePeriodList'
import Tabs from '../../../Shared/Tabs'
import { useStores } from '../../../../shared_components/Hooks'
import { CONTACT_FORM_STATE } from '../../../../lib/utils/stores'

const PricePeriodModal = () => {
  const { store: { uiStateStore, pricePeriodStore } } = useStores()

  const tabs = pricePeriodStore.pricePeriodYears.map(year => (
    {
      tabId: `price-period-${year}-tab`,
      panelId: `price-period-${year}-panel`,
      ref: React.createRef(),
      label: year,
      isActive: year === pricePeriodStore.selectedPeriodYear
    }))

  const activeTab = tabs.find(tab => tab.label === pricePeriodStore.selectedPeriodYear) || {}

  const tabAction = tab => pricePeriodStore.setSelectedPeriodYear(tab.label)

  const closeModal = () => {
    uiStateStore.togglePricePeriodModal(false)
  }

  const handleClick = () => {
    uiStateStore.setContactFormState(CONTACT_FORM_STATE.makeContact)
    uiStateStore.togglePricePeriodModal(false)
  }

  return (
    <Modal
      isOpen={uiStateStore.isPricePeriodModalOpen}
      onRequestClose={closeModal}
      shouldReturnFocusAfterClose={true}
      ariaHideApp={false}
      aria={{
        label: 'Pricing and Availability modal'
      }}
    >
      <div className='PricePeriodModal'>
        <div className='PricePeriodModal-content'>
          <h2 className='PricePeriodModal-title'>
            Pricing and Availability
          </h2>

          <div className='PricePeriodModal-nav'>
            <Tabs
              tabs={tabs}
              tabAction={tabAction}
              tabsWrapperClassName='PricePeriodNav PricePeriodNav-withoutBorder'
              tabClassName='PricePeriodNav-year'
              ariaLabel='Price period modal'
            />
          </div>

          {tabs.length > 1 ? (
            <div
              className='PricePeriodModal-list'
              role='tabpanel'
              id={activeTab.panelId}
              aria-labelledby={activeTab.tabId}
            >
              <PricePeriodList/>
            </div>
          ) : (
            <div className='PricePeriodModal-list'>
              <PricePeriodList/>
            </div>
          )}
          <div className='PricePeriodModal-actions'>
            <div className='PricePeriodModal-action'>
              <button className='Button Button--secondary Button--paddingX-16' onClick={closeModal}>
                Cancel
              </button>
            </div>
            {pricePeriodStore.contactTo?.showButton && (
              <div className='PricePeriodModal-action'>
                <button className='Button Button--primary Button--paddingX-16' onClick={handleClick}>
                  Contact {pricePeriodStore.contactTo.type}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default observer(PricePeriodModal)
