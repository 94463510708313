import React from 'react'
import { Provider } from 'mobx-react'

import rootStoreInstance from '../stores/rootStoreInstance'
import ErrorBoundary from '../../Shared/ErrorBoundary'

import Amenities from './Amenities'

const StateFulAmenities = props => {
  rootStoreInstance.setInitialDataAmenities(props)

  return (
    <ErrorBoundary>
      <Provider store={rootStoreInstance}>
        <Amenities />
      </Provider>
    </ErrorBoundary>
  )
}

export default StateFulAmenities
