export const getPeriodsByYear = (year, prices) => {
  const selectedPeriod = prices.find(i => i.year === year)

  if (!selectedPeriod) { return [] }

  return Object.values(selectedPeriod.prices)
}

export const getAvailablePeriods = (prices) => {
  return prices.flatMap(({ year, prices: yearlyPrices }) =>
    Object.values(yearlyPrices)
      .filter(price => price.availability)
      .map(price => ({ ...price, year }))
  );
};

export const getDefautlAvailablePeriods = (prices, currentPeriod) => {
  const availablePrices = getAvailablePeriods(prices)
  const currentPeriodIndex = availablePrices.findIndex(price => price.name === currentPeriod)

  return availablePrices.slice(currentPeriodIndex, currentPeriodIndex + 3)
}

export const groupPeriodsBySeasons = (periods, seasons, selectedPeriodYear) => {
  return seasons.reduce((acc, season) => {
    const seasonPeriods = periods.filter(period => period.season === season && period.year === selectedPeriodYear)

    acc[season] = seasonPeriods || []

    return acc
  }, {})
}
