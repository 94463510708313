import PropTypes from 'prop-types'

export const itemPropType = PropTypes.shape({
  field: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
})

export const sectionPropType = PropTypes.shape({
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(itemPropType).isRequired
})
