import React from 'react'
import PropTypes from 'prop-types'

import { useResponsive } from '../../../shared_components/Hooks'

import { sectionPropType } from './propTypes'
import Section from './Section'
import ViewMoreLess from './ViewMoreLess'

const Category = ({ category }) => {
  const { sections } = category
  const { isMobile } = useResponsive()

  const firstTwoSections = sections.slice(0, 2)
  const otherSections = sections.slice(2)

  const getSectionContent = section => (<Section key={section.title} section={section} />)

  return (
    <div className='Amenities-category'>
      <h2 className='Amenities-title'>{category.title}</h2>
      <div className='Amenities-content'>
        {firstTwoSections.map(getSectionContent)}
        {!isMobile && (otherSections.map(getSectionContent))}
      </div>
      {(isMobile && otherSections.length > 0) && (
        <ViewMoreLess>
          <div className='Amenities-content'>
            {otherSections.map(getSectionContent)}
          </div>
        </ViewMoreLess>
      )}
    </div>
  )
}

Category.propTypes = {
  category: PropTypes.shape({
    title: PropTypes.string.isRequired,
    sections: PropTypes.arrayOf(sectionPropType).isRequired
  })
}

export default Category
