import { action, observable, makeObservable } from 'mobx'

class PricePeriodStore {
  @observable defaultPeriod = ''
  @observable currentPrice = ''
  @observable currentPeriod = ''
  @observable selectedPeriods = []
  @observable currentPeriodYear = null
  @observable selectedPeriodYear = null
  @observable isAvailableIcon = false
  @observable statusInfo = {}
  @observable contactTo = {}
  @observable periodSeasons = []

  constructor() {
    makeObservable(this)
  }

  @action('[PricePeriodStore] Set Initial Data')
  setInitialData = ({ currentPrice, currentPeriod, statusInfo, periodYears, year, prices, isAvailableIcon, contactTo, periodSeasons }) => {
    this.currentPrice = currentPrice
    this.currentPeriod = this.defaultPeriod = currentPeriod
    this.pricePeriodYears = periodYears
    this.currentPeriodYear = this.selectedPeriodYear = parseInt(year, 10)
    this.prices = prices
    this.statusInfo = statusInfo
    this.isAvailableIcon = isAvailableIcon
    this.contactTo = contactTo
    this.periodSeasons = periodSeasons
  }

  @action('[PricePeriodStore] set current price period')
  setSelectedPeriodYear = v => {
    this.selectedPeriodYear = v
  }

  @action('[PricePeriodStore] Set selected period')
  setSelectedPeriods = v => {
    this.selectedPeriods = v
  }

  @action('[PricePeriodStore] Update Info')
  updateInfo = ({ statusInfo }) => {
    this.statusInfo = statusInfo
  }
}

export default PricePeriodStore
