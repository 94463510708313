import React from 'react'
import { observer } from 'mobx-react'

import { useStores } from '../../../shared_components/Hooks'

import Category from './Category'

const Amenities = () => {
  const { store: { hdpStore } } = useStores()

  return (
    <div className='Amenities'>
      {hdpStore.amenities.map(category => (<Category key={category.title} category={category} />))}
    </div>
  )
}

export default observer(Amenities)
