import React from 'react'
import { observer } from 'mobx-react'
import { getAvailablePeriods, groupPeriodsBySeasons } from '../utils'
import { useStores } from '../../../../shared_components/Hooks'
import { isEmpty } from '../../../../lib/utils/collection'
import { CalendarUnavailable } from '../../../../shared_components/Svg'

const PricePeriodList = () => {
  const { store: { pricePeriodStore } } = useStores()
  const { selectedPeriods, selectedPeriodYear, prices, periodSeasons } = pricePeriodStore

  const isSamePeriods = (selected, period) => {
    return selected.name === period.name && selected.year === period.year
  }

  const isPeriodSelected = (period) => {
    return selectedPeriods.some((selected) => isSamePeriods(selected, period))
  }

  const handleToggle = (period) => () => {
    const isSelected = isPeriodSelected(period)

    if (isSelected) {
      pricePeriodStore.setSelectedPeriods(
        selectedPeriods.filter((selected) => !isSamePeriods(selected, period))
      )
    } else {
      pricePeriodStore.setSelectedPeriods([...selectedPeriods, period])
    }
  }

  const availablePeriods = getAvailablePeriods(prices)
  const availablePeriodsBySeasons = groupPeriodsBySeasons(availablePeriods, periodSeasons, selectedPeriodYear)

  return (
    <div className='PricePeriodList'>
      {Object.entries(availablePeriodsBySeasons).map(([season, periods]) => {
        if (isEmpty(periods)) {
          return (
            <div key={season} className='PricePeriodList-season'>
              <h3 className='PricePeriodList-season--title'>{season}</h3>
              <div className='PricePeriodList-message'>
                <CalendarUnavailable className='PricePeriodList-message--messageIcon' />
                <p className='PricePeriodList-message--messageText'>
                  No dates available in {season} {selectedPeriodYear}
                </p>
              </div>
            </div>
          )
        }

        return (
          <div key={season} className='PricePeriodList-season'>
            <h3 className='PricePeriodList-season--title'>{season}</h3>
            <ul className='PricePeriodList-season--list'>
              {periods.map((period, index) => (
                <li key={index} className='PricePeriodList-item'>
                  <div className='PricePeriodList-item--checkbox'>
                    <div className='Checkbox'>
                      <label className='Checkbox-label' htmlFor={`period-${season}-${index}`}>
                        <input
                          id={`period-${season}-${index}`}
                          name={period.name}
                          type='checkbox'
                          checked={isPeriodSelected(period)}
                          className='Checkbox-input'
                          onChange={handleToggle(period)}
                        />
                        <span className='Checkbox-icon' />
                      </label>
                    </div>
                  </div>
                  <div className='PricePeriodList-item--details'>
                    <span className='PricePeriodList-item--name'>{period.name}</span>
                    <span className='PricePeriodList-item--note'>{period.note}</span>
                  </div>
                  <span className='PricePeriodList-item--price'>{period.price}</span>
                </li>
              ))}
            </ul>
          </div>
        )
      })}
    </div>
  )
}

export default observer(PricePeriodList)
