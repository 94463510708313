import React from 'react'
import cn from 'classnames'

import { sectionPropType } from './propTypes'
import SectionItem from './SectionItem'

const Section = ({ section }) => {
  const { title, items} = section

  return (
    <div className='Amenities-section'>
      <h3 className='Amenities-subSection'>{title}</h3>
      <div className={cn('Amenities-itemList', { isSingle: items.length === 1 })}>
        {items.map(item => (<SectionItem key={item.title} item={item} />))}
      </div>
    </div>
  )
}

Section.propTypes = {
  section: sectionPropType
}

export default Section
