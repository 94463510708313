import React, { useState, useEffect } from 'react'
import cn from 'classnames'
import { observer } from 'mobx-react'
import { useStores } from '../../../shared_components/Hooks'

import { default as poolUrl } from 'images/modules/Hdp/Highlights/i-pool.svg'
import { default as tennisUrl } from 'images/modules/Hdp/Highlights/i-tennis.svg'
import { default as spaUrl } from 'images/modules/Hdp/Highlights/i-spa.svg'
import { default as waterfrontUrl } from 'images/modules/Hdp/Highlights/i-waterfront.svg'
import { default as parkingUrl } from 'images/modules/Hdp/Highlights/i-parking.svg'
import { default as locationUrl } from 'images/modules/Hdp/Highlights/i-location.svg'
import { default as defaultUrl } from 'images/modules/Hdp/Highlights/i-default.svg'

const Highlights = () => {
  const { store: { hdpStore: { highlights } } } = useStores()

  const [isOdd, setOdd] = useState(false)

  const iconMap = {
    'highlights-pool': poolUrl,
    'highlights-tennis': tennisUrl,
    'highlights-spa': spaUrl,
    'highlights-waterfront': waterfrontUrl,
    'highlights-parking': parkingUrl,
    'highlights-location': locationUrl,
  }

  useEffect(() => {
    setOdd(highlights.length % 2 !== 0)
  }, [highlights])

  return (
    <div className={cn('Highlights', { isOdd })}>
      <div className="Highlights-container">
        {highlights.map((highlight) => (
          <div key={highlight.key} className="Highlights-item">
            <img src={iconMap[highlight.key] || defaultUrl} className="Highlights-item--icon" alt={highlight.title} />
            <div className="Highlights-item--content" >
              <span className="Highlights-item--title">{highlight.title}</span>
              <span className="Highlights-item--description">{highlight.description}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default observer(Highlights)
