import PropTypes from 'prop-types'
import React from 'react'

const OpenHouse = ({ closestOpenHouseDate, closestOpenHouseTime }) => {
  return (
    <div className='OpenHouseCard'>
      <div className='OpenHouseCard-items'>
        <div className='OpenHouseCard-item'>
          <img
            src='/assets/modules/Shared/i-openhouse-white.svg'
            className='OpenHouseCard-icon'
            alt='Open house'
          />
          <div className='OpenHouseCard-info OpenHouseCard-info--date'>
            {closestOpenHouseDate}
          </div>
          <div className='OpenHouseCard-info'>
            {closestOpenHouseTime}
          </div>
        </div>
      </div>
    </div>
  )
}

OpenHouse.propTypes = {
  closestOpenHouseDate: PropTypes.string.isRequired,
  closestOpenHouseTime: PropTypes.string.isRequired
}

export default OpenHouse
