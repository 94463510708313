/* eslint camelcase: 0 */
/* eslint no-undefined: 0 */
/* eslint no-console: 0 */

import { action, observable, computed, makeObservable } from 'mobx'
import { lens } from 'lorgnette'
import { isServer } from '../../../lib/utils/common'
import { fromServerToClient } from '../../../lib/utils/collection'
import { listingCaptchaKey } from '../../../lib/config/recaptcha'
import { fetchFromSessionStorage, saveToSessionStorage } from '../../../lib/utils/browserStorage'
import { VIEWED_LISTING_ID } from '../../../lib/constants/storageKeys'

const activeItemLens = listing => lens.firstOf(l => l.id === listing.id)

class HdpStore {
  @observable images = []
  @observable openListings = []
  @observable title = ''
  @observable lastVerified = ''
  @observable amenities = {}
  @observable listingBy = ''
  @observable officeAddress = ''
  @observable description = ''
  @observable agents = []
  @observable isListingSaved = false
  @observable saveAction = {}
  @observable listingId = ''
  @observable addressOrTitle = ''
  @observable listingUrl = ''
  @observable listingClass = ''
  @observable sourceId
  @observable currentOpenListing = {}
  @observable contactTo = {}
  @observable policies = {}
  @observable statusInfo = {}
  @observable hasOpenListings = false
  @observable zillow3dTour = ''
  @observable videoTour = ''
  @observable highlights = []

  constructor({ transport, sessionStorage, eventBus, history, gaEvents, root }) {
    makeObservable(this)

    this.transport = transport
    this.sessionStorage = sessionStorage
    this.history = history
    this.gaEvents = gaEvents
    this.root = root

    if (!isServer()) {
      eventBus.addEventListener('transport.Listing.updateSavedStatusTask', this.updateListingSaveStatus)
      eventBus.addEventListener('transport.Listing.updateSavedStatus', this.updateListingSaveStatus)
    }
  }

  @computed get hasImages() {
    return this.images.length > 0
  }

  @computed get currentIndexOpenListing() {
    const index = this.openListings.findIndex(openListing => openListing.id === this.listingId)
    return index === -1 ? 0 : index
  }

  @action('[HdpStore] Set Initial Data image gallery')
  setInitialDataImageGallery = ({
    images,
    mapInfo,
    viewOnGmapsInfo,
    interestingPlaces,
    contactTo,
    hasOpenListings,
    videoTour
  }) => {
    this.images = images || this.images
    this.mapInfo = mapInfo || this.mapInfo
    this.viewOnGmapsInfo = viewOnGmapsInfo || this.viewOnGmapsInfo
    this.interestingPlaces = interestingPlaces || this.interestingPlaces
    this.contactTo = contactTo
    this.hasOpenListings = hasOpenListings
    this.videoTour = videoTour
  }

  @action('[HdpStore] Set Initial Data open listing info')
  setInitialDataOpenListings = ({ openListings }) => {
    this.openListings = openListings
  }

  @action('[HdpStore] Set Initial Data title')
  setInitialDataListingTitle = ({ title }) => {
    this.title = title
  }

  @action('[HdpStore] Set Initial Data Last Verified')
  setInitialDataLastVerified = ({ verified }) => {
    this.lastVerified = verified
  }

  @action('[HdpStore] Set Initial Data Amenities')
  setInitialDataAmenities = ({ amenities }) => {
    this.amenities = amenities
  }

  @action('[HdpStore] Set Initial Data Office Address')
  setInitialDataOfficeAddress = ({ officeAddress }) => {
    this.officeAddress = officeAddress
  }

  @action('[HdpStore] Set Initial Data Listing By')
  setInitialDataListingBy = ({ listingBy }) => {
    this.listingBy = listingBy
  }

  @action('[HdpStore] Set Initial Data description')
  setInitialDataListingDescription = ({ description }) => {
    this.description = description
  }

  @action('[HdpStore] Set Initial Data listing details')
  setInitialDataListingDetails = ({ agents, sourceId, policies }) => {
    this.agents = agents
    this.sourceId = sourceId
    this.policies = policies
  }

  @action('[HdpStore] Set Initial Data saved button')
  setInitialDataSavedButton = ({ isSaved, saveAction, listingClass }) => {
    this.isListingSaved = isSaved
    this.saveAction = saveAction
    this.listingClass = listingClass
  }

  @action('[HdpStore] Set 3DTour button URL')
  set3DTourURL = ({zillow3dTour}) => {
    this.zillow3dTour = zillow3dTour
  }

  @action('[HdpStore] Set Video Tour button URL')
  setVideoTourURL = ({ videoTour }) => {
    this.videoTour = videoTour
  }

  @action('[HdpStore] Set Initial Data share listing')
  setInitialDataShareListing = ({ addressOrTitle, listingUrl, listingId }) => {
    this.addressOrTitle = addressOrTitle
    this.listingUrl = listingUrl
    this.listingId = listingId
  }

  @action('[HdpStore] Set Initial Data status info')
  setInitialDataStatusInfo = ({ statusInfo }) => {
    this.statusInfo = statusInfo
  }

  @action('[HdpStore] Update listing info')
  updateListingInfo = index => {
    this.currentOpenListing = this.openListings[index]

    this.images = this.currentOpenListing.photos
    this.title = this.currentOpenListing.title
    this.listingBy = this.currentOpenListing.listingBy
    this.officeAddress = this.currentOpenListing.officeAddress
    this.description = this.currentOpenListing.description
    this.agents = this.currentOpenListing.listors
    this.isListingSaved = this.currentOpenListing.saved
    this.saveAction = this.currentOpenListing.saveAction
    this.listingUrl = this.currentOpenListing.url
    this.listingId = this.currentOpenListing.id
    this.sourceId = this.currentOpenListing.sourceId
    this.statusInfo = this.currentOpenListing.statusInfo
    this.videoTour = this.currentOpenListing.videoTour

    this.root.photoGalleryUIStateStore.updateVideoTour(this.videoTour)
    this.updateHistoryState()
    this.gaEvents.Hdp.openListing()
    this.gaEvents.Hdp.openListingCarousel()
  }

  @action('[HdpStore] update listing save status')
  updateListingSaveStatus = ({ detail: { data } }) => {
    const listing = fromServerToClient(data)

    this.openListings = activeItemLens(data).update(this.openListings, ol => {
      ol.saveAction = listing.links.saveAction
      ol.saved = listing.saved
      return ol
    })

    if (this.listingId === listing.id) {
      this.saveAction = listing.links.saveAction
      this.isListingSaved = listing.saved
      const eventLabel = listing.saved ? 'save' : 'unsave'

      this.gaEvents.Hdp.saveListing(eventLabel)
    }
  }

  @action('[HdpStore] Set Initial Data Highlights')
  setInitialDataHighlights = ({ highlights }) => {
    this.highlights = highlights
  }

  updateViewedListings = () => {
    const viewedListings = fetchFromSessionStorage(VIEWED_LISTING_ID) || {}

    if (!viewedListings[this.listingId]) {
      saveToSessionStorage(VIEWED_LISTING_ID, { ...viewedListings, [this.listingId]: this.listingId })
    }
  }

  updateSaveStatus = () => {
    const { url, method } = this.saveAction

    this.transport.Listing.updateSavedStatus({
      url, method, captchaKey: listingCaptchaKey(this)
    })
      .catch(error => { console.log(error) })
  }

  sendShowAgentNumberAnalytics = () => {
    this.gaEvents.Hdp.showAgentNumber()
  }

  updateHistoryState = () => {
    if (this.listingUrl) {
      this.history.replaceState({
        state: null,
        url: this.listingUrl
      })
    }
  }
}

export default HdpStore
