import React from 'react'
import { observer } from 'mobx-react'

import { useStores } from '../../../shared_components/Hooks'
import { formatDate } from '../../../lib/utils/date'

const TOOLTIP = 'Listing agent last confirmed details and availability as accurate on this date'

const LastVerified = () => {
  const { store: { hdpStore } } = useStores()

  return (
    // eslint-disable-next-line react/no-unknown-property
    <section className='LastVerified' tooltip={TOOLTIP}>
      <p className='LastVerified-text'>Last Verified:</p>
      <p className='LastVerified-text LastVerified-date'>{formatDate(hdpStore.lastVerified)}</p>
    </section>
  )
}

export default observer(LastVerified)
