import React, { useEffect } from 'react'
import { Provider } from 'mobx-react'
import rootStoreInstance from '../stores/rootStoreInstance'
import Highlights from './Highlights'
import { withErrorBoundary } from '../../Shared/ErrorBoundary'

const StateFulHighlights = props => {
  useEffect(()=>{
    rootStoreInstance.setInitialDataHighlights(props)
  }, [props])

  return (
    <Provider store={rootStoreInstance}>
      <Highlights/>
    </Provider>
  )
}

export default withErrorBoundary(StateFulHighlights)
