import React from 'react'

import { itemPropType } from './propTypes'

const SectionItem = ({ item }) => {
  const { field, value } = item

  return (
    <div className='Amenities-item'>
      <span className='Amenities-circle' />
      <p className='Amenities-itemText'>
        {field && `${field}: `}<span className='Amenities-itemValue'>{value}</span>
      </p>
    </div>
  )
}

SectionItem.propTypes = {
  item: itemPropType
}

export default SectionItem
