import React from 'react'
import { useStores } from '../../../shared_components/Hooks'
import { observer } from 'mobx-react'

const ListingTitle = () => {
  const { store: { hdpStore } } = useStores()

  return (
    <h2
      className='Heading Heading--titleBig2'
      dangerouslySetInnerHTML={{ __html: hdpStore.title }}
    />
  )
}

export default observer(ListingTitle)
