/* eslint camelcase: 0 */

import { observable, observe, action, computed, makeObservable } from 'mobx'
import { lens, just, multi } from 'lorgnette'
import * as R from 'ramda'
import { debounce, isDefined, noop, isNull } from '../../../../lib/utils/common'
import { intersection, fromServerToClient, removeFromArray, isPresent } from '../../../../lib/utils/collection'
import { priceLabelWithCutOff } from '../../../../lib/utils/money'
import { buildSelectedOptionsWithParents, buildTreeTownOptions } from '../../../../lib/dataMappings/treeUtils'
import { getId } from '../../../../lib/utils/selectors'
import { SRP_FORM_VALUE_CHANNEL } from '../../../../lib/constants/channels'
import {
  SRP_SEARCH_CRITERIA,
  SRP_SEARCH_VALUES_SCOPE,
  SRP_SEARCH_INFO,
  SRP_SEARCH_INFO_URL
} from '../../../../lib/constants/search'
import { toLatLngLiteral } from '../utils'

export const DEBOUNCE_TIMEOUT = 2000

const commonListingFields = {
  sales: [
    'videoTour',
    'zillow3dTour',
    'townNames',
    'beds',
    'baths',
    'acreage',
    'sqft',
    'unitTypes',
    'amenityNames',
    'openHouse',
    'byOwner',
    'byAgent'
  ],
  rentals: [
    'videoTour',
    'zillow3dTour',
    'townNames',
    'beds',
    'baths',
    'acreage',
    'sqft',
    'unitTypes',
    'amenityNames',
    'openHouse',
    'byOwner',
    'byAgent'
  ],
  lands: [
    'videoTour',
    'zillow3dTour',
    'townNames',
    'acreage',
    'sqft',
    'openHouse',
    'byOwner',
    'byAgent'
  ]
}

export const SPECIAL_AREA = 'South of Highway'
const justBoolean = R.compose(just, Boolean)

const specAreaAmenityLens = lens.prop('amenityNames').firstOf(name => name === SPECIAL_AREA)
const specAreaTownsLens = lens.prop('townNames').firstOf(name => name === SPECIAL_AREA)
const isSpecAreaInAmenities = value => (
  specAreaAmenityLens.get(value).then(justBoolean).getOr(false)
)
const isSpecAreaInTowns = value => (
  specAreaTownsLens.get(value).then(justBoolean).getOr(false)
)
const updateSpecialAreaInTowns = values => R.cond([
  [R.equals([true, false, false]),
    () => lens.prop('townNames').update(values, v => isPresent(v) ? [...v, SPECIAL_AREA] : [SPECIAL_AREA])],

  [R.equals([false, true, true]),
    () => lens.prop('townNames').update(values, v => removeFromArray(v, SPECIAL_AREA))],

  [R.T, R.always(values)]
])
const updateSpecialAreaInAmenities = values => {
  const amenityNamesLens = lens.prop('amenityNames')
  const specialAreaPropLens = lens.prop(SPECIAL_AREA, false)
  const valuesLens = multi(amenityNamesLens, specialAreaPropLens)

  const addToAmenityNames = v => isPresent(v) ? [...v, SPECIAL_AREA] : [SPECIAL_AREA]
  const enableSpecialArea = R.always(true)

  const removeFromAmenityNames = v => removeFromArray(v, SPECIAL_AREA)
  const disableSpecialArea = R.always(false)

  return R.cond([
    [R.equals([true, false, false]),
      () => valuesLens.update(values, addToAmenityNames, enableSpecialArea)],

    [R.equals([false, true, true]),
      () => valuesLens.update(values, removeFromAmenityNames, disableSpecialArea)],

    [R.T, R.always(values)]
  ])
}

const hasTownNames = newValue => lens.prop('townNames').get(newValue).then(isPresent)
const hasPolygon = newValue => lens.prop('searchPolygon').get(newValue).then(isPresent)

class ValueStateStore {
  @observable values = {}
  @observable selectedOptionsGroup = []
  @observable defaultValues = {}
  @observable countResults = 0
  @observable towns = {}
  @observable mapBounds = {}
  @observable searchPolygon = []
  bedsValueLimits = { min: 1, max: 5 }
  bathValueLimits = { min: 1, max: 6 }

  initialValues = {}

  smokingPolicies = ['Any', 'Smoking Allowed', 'No Smoking']
  petPolicies = ['Any', 'Pets Allowed', 'Pets Considered', 'No Pets']
  amenities = ['Pool', 'Tennis', 'Central AC', 'Fireplace', 'Hot Tub', 'Garage', 'South of Highway']
  viewAmenities = ['Oceanfront', 'Waterfront', 'Waterview']
  allAmenities = [...this.amenities, ...this.viewAmenities]

  optionsById = {}

  constructor({ transport, location, channel, sessionStorage, root }) {
    makeObservable(this)

    this.transport = transport
    this.location = location
    this.sessionStorage = sessionStorage
    this.root = root
    this.channel = channel
    this.channel.init(SRP_FORM_VALUE_CHANNEL)

    this.setObservers()
  }

  setInitialState({ initialState }) {
    this.setState({
      values: this.sanitizeValues(initialState.ValueStateStore.values),
      towns: initialState.ValueStateStore.towns,
      mapBounds: initialState.ValueStateStore.values.mapBounds,
      searchPolygon: initialState.ValueStateStore.values.searchPolygon,
      defaultValues: initialState.ValueStateStore.defaultValues
    })

    this.initialValues = this.values
  }

  setObservers() {
    observe(this, 'values', ({ newValue, oldValue }) => {
      this.values = updateSpecialAreaInTowns(this.values)([
        isSpecAreaInAmenities(newValue),
        isSpecAreaInAmenities(oldValue),
        isSpecAreaInTowns(newValue)
      ])
    })

    observe(this, 'values', ({ newValue, oldValue }) => {
      this.values = updateSpecialAreaInAmenities(this.values)([
        isSpecAreaInTowns(newValue),
        isSpecAreaInTowns(oldValue),
        isSpecAreaInAmenities(newValue)
      ])
    })
  }

  @action('[ValueStateStore] Reset state')
  setState = ({ values, towns, defaultValues }, { current } = this.root.listingTypeStore) => {
    const commonListingValues = commonListingFields[current].reduce((res, name) => {
      res[name] = values[name] || this.values[name]
      return res
    }, {})

    this.values = { ...values, ...commonListingValues }
    this.towns = towns
    this.selectedOptionsGroup = values.townNames || []
    this.defaultValues = defaultValues
    this.setValuesFromDefaultValues()

    this.values.smokingPolicy = this.smokingPolicyValue
    this.values.petPolicy = this.petPolicyValue

    this.values = { ...this.values, ...this.allAmenityNames }

    this.channel.put(SRP_FORM_VALUE_CHANNEL, { value: this.values })
  }

  @action('[ValueStateStore] Update input value')
  setInputValue = (name, value) => {
    const newValues = {
      ...this.values,
      [name]: value
    }

    if (name === 'beds' || name === 'baths') {
      newValues[name] = Number(value)
    }

    const newRuleNames = [newValues.smokingPolicy, newValues.petPolicy]

    this.values = {
      ...newValues,
      ruleNames: newRuleNames.filter(n => isDefined(n) && n !== 'Any'),
      amenityNames: this.allAmenities.filter(n => newValues[n])
    }
  }

  @action('[ValueStateStore] Reset to initial values')
  resetToInitialValues = () => {
    this.values = this.initialValues
    this.setValuesFromDefaultValues()
  }

  @action('[ValueStateStore] Reset inputs')
  resetInputs = names => {
    this.values = { ...R.omit(names, this.values) }
  }

  @action('[ValueStateStore] Set selected options group')
  setSelectedOptionsGroup = value => {
    this.selectedOptionsGroup = value
  }

  @action('[ValueStateStore] Reset inputs')
  resetInputToInitialValue = name => {
    this.values = { ...this.values, [name]: this.initialValues[name] }
  }

  @action('[ValueStateStore] Reset all inputs')
  resetAllInputs = () => {
    // #setState tries to get bunch of data from old values
    // so we need to reset it manually
    this.values = {}

    this.setState({
      values: {},
      towns: this.towns,
      defaultValues: this.defaultValues
    })
  }

  @action('[ValueStateStore] Preset defaults')
  setValuesFromDefaultValues = () => {
    this.values = {
      ...this.values,
      year: this.values.year || this.defaultValues.year,
      unitTypes: this.defaultValues.unitTypes
    }
  }

  @action('[ValueStateStore] Reset to default values')
  resetToDefaultValues = () => {
    this.values = {
      ...this.values,
      status: this.defaultValues.status,
      unitTypes: this.defaultValues.unitTypes,
      year: this.defaultValues.year
    }
  }

  @action('[ValueStateStore] Submit form')
  submit = ({ current, listingTypes }) => {
    const valuesScope = current === 'rentals' ? 'search_rental' : 'search_sale'

    this.transport.Search
      .submit(listingTypes[current].searchUrl, { [valuesScope]: this.valuesForSubmit })
      .then(({ redirect_to }) => {
        this.location.redirect(redirect_to)
      })
      .catch(noop)
  }

  @action('[ValueStateStore] get count results')
  getSearchInfo = ({ current, listingTypes }, { saveAsInitial = false } = {}) => {
    if (isNull(current)) {
      return
    }

    const valuesScope = current === 'rentals' ? 'search_rental' : 'search_sale'
    this.transport.Search
      .getSearchInfo(listingTypes[current].countResultsUrl, { [valuesScope]: this.valuesForSubmit })
      .then(response => {
        const { totalCount } = fromServerToClient(response)

        this.setCountResults(totalCount)
        if (saveAsInitial) {
          this.initialValues = { ...this.initialValues, countResults: totalCount }
        }
      })
      .catch(noop)
  }

  getSearchInfoDebounced = debounce(() => {
    this.getSearchInfo(this.root.listingTypeStore)
  }, 500)

  @action('[ValueStateStore] set count results')
  setCountResults = v => {
    this.countResults = v
  }

  @computed
  get valuesForSubmit() {
    const updateStatus = status => {
      const s = isDefined(status) ? status : []
      return s.length === 0 ? ['any'] : s
    }
    const statusLens = lens.prop('status')

    const updateTownNames = townNames => (
      isDefined(townNames)
        ? removeFromArray(townNames, SPECIAL_AREA)
        : townNames
    )
    const townNamesLens = lens.prop('townNames')

    const valuesLens = multi(statusLens, townNamesLens)

    let newValues = valuesLens.update(this.values, updateStatus, updateTownNames)

    if (hasTownNames(newValues)) {
      newValues = R.omit(['mapBounds', 'searchPolygon'], newValues)
    }
    if (hasPolygon(newValues)) {
      newValues = R.omit(['mapBounds'], newValues)
    }

    if (!newValues.beds) {
      newValues = R.omit(['beds'], newValues)
    }

    if (!newValues.baths) {
      newValues = R.omit(['baths'], newValues)
    }

    return newValues
  }

  saveSearchInfo() {
    const { current, listingTypes } = this.root.listingTypeStore
    const valuesScope = current === 'rentals' ? 'search_rental' : 'search_sale'

    this.sessionStorage.saveToSessionStorage(SRP_SEARCH_VALUES_SCOPE, valuesScope)
    this.sessionStorage.saveToSessionStorage(SRP_SEARCH_CRITERIA, this.valuesForSubmit)
    this.sessionStorage.saveToSessionStorage(SRP_SEARCH_INFO_URL, listingTypes[current].countResultsUrl)

    const page = (new URL(this.location.global.href)).searchParams.get('page') || 1

    this.transport.Search
      .getSearchInfo(listingTypes[current].countResultsUrl, { [valuesScope]: this.valuesForSubmit, page })
      .then(data => {
        const searchInfo = fromServerToClient(data)
        const savedSearchInfo = this.sessionStorage.fetchFromSessionStorage(SRP_SEARCH_INFO)
        const savedSearchItems = lens.prop('items').get(savedSearchInfo).getOr({})
        const savedSearchId = lens.prop('searchId').get(savedSearchInfo).getOr(null)

        const newSearchInfo = lens.prop('items')
          .update(searchInfo, items => (
            savedSearchId === searchInfo.searchId
              ? { ...savedSearchItems, [page]: items }
              : { [page]: items }
          ))

        this.sessionStorage.saveToSessionStorage(SRP_SEARCH_INFO, newSearchInfo)
      })
      .catch(noop)
  }

  sanitizeValues = values => ({
    ...values,
    status: values.status && values.status.filter(v => v !== 'any')
  })

  debounceSubmitFunction = debounce(values => { this.submit(values) }, DEBOUNCE_TIMEOUT)

  debounceSubmitCancel = () => {
    clearTimeout(this.debounceSubmitFunction.timeout)
  }

  @action('[ValueStateStore] Delayed form submit')
  debounceSubmit = this.debounceSubmitFunction

  @action('[ValueStateStore] Immediate form submit with planned attempt cancelled')
  immediateSubmit = values => {
    this.debounceSubmitCancel()
    this.submit(values)
  }

  @action('[ValueStateStore] Set Search polygon')
  setSearchPolygon = polygon => {
    const literalCollection = toLatLngLiteral(polygon)
    this.searchPolygon = literalCollection
    this.setInputValue('searchPolygon', literalCollection)
  }

  @action('[ValueStateStore] Immediate mapSearch form submit with planned attempt cancelled')
  mapSearchSubmit = values => {
    this.setInputValue('townNames', [])
    this.setInputValue('mapBounds', this.mapBounds)
    this.setInputValue('searchPolygon', this.searchPolygon)
    this.immediateSubmit(values)
  }

  @computed
  get primarySelectedOptions() {
    return this.treeTownOptions.filter(o => (
      this.selectedOptionsGroup.includes(getId(o))
    ))
  }

  @computed
  get selectedOptionsWithParents() {
    return buildSelectedOptionsWithParents(this.treeTownOptions, this.primarySelectedOptions, this.optionsById)
  }

  @computed
  get ruleNames() {
    return [this.values.smokingPolicy, this.values.petPolicy]
  }

  @computed
  get allAmenityNames() {
    return this.allAmenities.reduce((acc, cur) => {
      acc[cur] = isDefined(this.values.amenityNames) && this.values.amenityNames.includes(cur)
      return acc
    }, {})
  }

  @computed
  get priceValues() {
    const { priceFrom: defaultPriceFrom, priceTo: defaultPriceTo } = this.defaultValues
    const { priceFrom = defaultPriceFrom, priceTo = defaultPriceTo } = this.values

    return [priceFrom, priceTo]
  }

  priceTitle(cutOff) {
    const { priceFrom, priceTo } = this.values

    if (isDefined(priceFrom) || isDefined(priceTo)) {
      const { priceFrom: defaultPriceFrom, priceTo: defaultPriceTo } = this.defaultValues

      return (
        [priceFrom || defaultPriceFrom, priceTo || defaultPriceTo].map(v => priceLabelWithCutOff(v, cutOff)).join(' - ')
      )
    }

    return 'Any Price'
  }

  @computed
  get bedsValue() {
    const { beds: defaultBeds } = this.defaultValues
    const { beds = defaultBeds } = this.values

    return beds > this.bedsValueLimits.max ? this.bedsValueLimits.max : beds
  }

  @computed
  get bathsValue() {
    const { baths: defaultBaths } = this.defaultValues
    const { baths = defaultBaths } = this.values

    return baths > this.bathValueLimits.max ? this.bathValueLimits.max : baths
  }

  @computed
  get bedsBathsTitle() {
    const { beds, baths } = this.values

    if (isDefined(beds) || isDefined(baths)) {
      const bedsValue = beds ? `${beds}+` : 'Any'
      const bathsValue = baths ? `${baths}+` : 'Any'

      return `${bedsValue} BD, ${bathsValue} BA`
    }

    return 'Beds + Baths'
  }

  @computed
  get bedsTitle() {
    const { beds } = this.values
    return isDefined(beds) ? `${beds} bedrooms` : null
  }

  @computed
  get acreageValue() {
    const { acreage: defaultAcreage } = this.defaultValues
    const { acreage = defaultAcreage } = this.values

    return acreage
  }

  @computed
  get sqftValue() {
    const { sqft: defaultSqft } = this.defaultValues
    const { sqft = defaultSqft } = this.values

    return sqft
  }

  @computed
  get smokingPolicyValue() {
    const { ruleNames = [] } = this.values
    return intersection(this.smokingPolicies, ruleNames)[0] || this.smokingPolicies[0]
  }

  @computed
  get petPolicyValue() {
    const { ruleNames = [] } = this.values
    return intersection(this.petPolicies, ruleNames)[0] || this.petPolicies[0]
  }

  @computed
  get viewAmenityValue() {
    const { amenityNames = [] } = this.values
    return intersection(this.viewAmenities, amenityNames) || []
  }

  @computed
  get treeTownOptions() {
    const withSpecialArea = [
      { id: SPECIAL_AREA, name: SPECIAL_AREA, children: [], parent: { id: 'The Hamptons' } },
      ...(R.sortBy(R.prop('id'), this.towns.collection))
    ]
    const options = buildTreeTownOptions(withSpecialArea)

    this.optionsById = R.indexBy(R.prop('id'), options)
    return options
  }
}

export default ValueStateStore
